import ApiService from "@/core/services/api.service";

// action types
export const API_MANAGE_SPECS_STRUCTURE_ACTION_LOAD = "action_manage_specs_structure_load";
export const API_MANAGE_COMMON_SPECS_ACTION_LOAD = "action_manage_common_specs_load";
export const API_MANAGE_SPECS_ADD_LOCATION_ACTION = "action_manage_specs_add_location";
export const API_MANAGE_SPECS_SAVE_LOCATION_ACTION = "action_manage_specs_save_location";
export const API_MANAGE_SPECS_SAVE_SPECS = "action_manage_specs_save_specs";
export const API_MANAGE_SPECS_FLEET_LOCATION_CONFLICTS_ACTION = "action_manage_specs_fleet_location_conflicts";
export const API_MANAGE_SPECS_DOWNLOAD_PDF_ACTION = "action_manage_specs_download_pdf";
export const API_MANAGE_SPECS_DOWNLOAD_EXCEL_ACTION = "action_manage_specs_download_excel";
export const API_MANAGE_SPECS_GET_FLEET_ID_ACTION = "action_manage_specs_get_fleet_id";

// muts
export const API_MANAGE_SPECS_SET_STRUCTURE = "muts_manage_specs_set_structure";
export const API_MANAGE_COMMON_SPECS_SET_STRUCTURE = "muts_manage_common_specs_set_structure";
export const API_MANAGE_SPECS_SET_FLEET_LOCATION_CONFLICTS = "muts_manage_specs_set_fleet_location_conflicts";

const state = {
    structure: {
        isHasAccess: false,
        hasFleetTabAccess: false,
        hasFleetLevelAccess: false,
        hasAllLocationLevelAccess: false,
        headOffice: "",
        hasTvAdminSecurity: false,
        canManageSpecs: false,
    },
    common_specs: [],
    pressure_format: '',
    tread_depth_format: '',

    fleet_location_conflicts: {
        fleet_locations: [],
        message: '',
        no_conflicts: true,
    },
    accountType: {
        Unknown: "0",
        LocalBook: "1",
        PreferredFleet: "2",
        NationalAccount: "3",
        CanadianNationalAccount: "4",
        Government: "5",
        GoodyearLeasingCustomer: "9",
    },
};

const getters = {
    getAccountTypeEnum() {
        return state.accountType;
    },
    getManageSpecsStructure() {
        return state.structure;
    },
    getCommonSpecs(){
        return state.common_specs;
    },
    getPressureFormat(){
        return state.pressure_format;
    },
    getTreadDepthFormat(){
        return state.tread_depth_format;
    },
    getFleetLocationConflicts(){
        return state.fleet_location_conflicts;
    },
    isHasAccess(){
        return state.structure.isHasAccess;
    },
    hasFleetTabAccess(){
        return state.structure.hasFleetTabAccess;
    },
    getHeadOffice(){
        return state.structure.headOffice;
    },
};

const actions = {
    [API_MANAGE_SPECS_DOWNLOAD_PDF_ACTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("manage_specs/download_pdf_file_spec_information", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log(response);
                    reject(response);
                });
        });
    },
    [API_MANAGE_SPECS_DOWNLOAD_EXCEL_ACTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("manage_specs/download_excel_file_spec_information", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log(response);
                    reject(response);
                });
        });
    },
    [API_MANAGE_SPECS_STRUCTURE_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("manage_specs/configurator_structure", params, context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_SPECS_SET_STRUCTURE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_MANAGE_COMMON_SPECS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("manage_specs/get_common_specs", params, context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_COMMON_SPECS_SET_STRUCTURE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_MANAGE_SPECS_FLEET_LOCATION_CONFLICTS_ACTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("manage_specs/get_fleet_location_conflicts", params, context)
            .then(({ data }) => {
                if(data.ResponseCode != 200)
                    reject(data.ResponseMessage);
                else{
                    context.commit(API_MANAGE_SPECS_SET_FLEET_LOCATION_CONFLICTS, data.data);
                    resolve(data.data);
                }
            })
            .catch(({ response }) => {
                if(response.data.ResponseMessage)
                    reject(response.data.ResponseMessage);
                else
                    reject(response);
            });
        });
    },
    [API_MANAGE_SPECS_ADD_LOCATION_ACTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("manage_specs/add_new_location", params, context)
            .then(({ data }) => {
                if(data.ResponseCode != 200)
                    reject(data.ResponseMessage);
                else
                    resolve(data.data);
            })
            .catch(({ response }) => {
                if(response.data.ResponseMessage)
                    reject(response.data.ResponseMessage);
                else
                    reject(response);
            });
        });
    },
    [API_MANAGE_SPECS_SAVE_LOCATION_ACTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put("manage_specs/save_location", params, context)
            .then(({ data }) => {
                if(data.ResponseCode != 200)
                    reject(data.ResponseMessage);
                else
                    resolve(data.data);
            })
            .catch(({ response }) => {
                if(response.data.ResponseMessage)
                    reject(response.data.ResponseMessage);
                else
                    reject(response);
            });
        });
    },
    [API_MANAGE_SPECS_SAVE_SPECS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put("manage_specs/save_specs", params, context)
            .then(({ data }) => {
                if(data.ResponseCode != 200)
                    reject(data.ResponseMessage);
                else
                    resolve(data);
            })
            .catch(({ response }) => {
                if(response.data.ResponseMessage)
                    reject(response.data.ResponseMessage);
                else
                    reject(response);
            });
        });
    },
    [API_MANAGE_SPECS_GET_FLEET_ID_ACTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("manage_specs/get_fleet_id", params, context)
                .then(({ data }) => {
                    if(data.ResponseCode != 200)
                        reject(data.ResponseMessage);
                    else
                        resolve(data.data);
                })
                .catch(({ response }) => {
                    if(response.data.ResponseMessage)
                        reject(response.data.ResponseMessage);
                    else
                        reject(response);
                });
        });
    },
};

const mutations = {
    [API_MANAGE_SPECS_SET_STRUCTURE](state, data) {
        state.structure.isHasAccess = !(data.ResponseCode == 403);
        state.structure.hasFleetTabAccess = data.has_fleet_tab_access;
        state.structure.hasFleetLevelAccess = data.has_fleet_Level_access;
        state.structure.hasAllLocationLevelAccess = data.has_all_location_level_access;
        state.structure.headOffice = data.head_office;
        state.structure.hasTvAdminSecurity = data.has_tv_admin_security;
        state.structure.canManageSpecs = data.can_manage_specs;
    },
    [API_MANAGE_COMMON_SPECS_SET_STRUCTURE](state, data) {
        state.common_specs = data.items;
        state.pressure_format = data.pressure_format;
        state.tread_depth_format = data.tread_depth_format;
    },
    [API_MANAGE_SPECS_SET_FLEET_LOCATION_CONFLICTS](state, data) {
        state.fleet_location_conflicts = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};